import * as React from 'react';
import { IColumn } from 'office-ui-fabric-react';
import createLazyContainer from '../lazyContainer';
import { Dictionary, EntityType, IExtensibleEntity, IWithName, SortDirection } from "../entities/common";
import * as Metadata from "../entities/Metadata";
import ProjectBudgetTotals from "../components/views/list/columns/project/BudgetTotals";
import PortfolioPercentCompleted from "../components/views/list/columns/portfolio/PercentCompleted";
import PortfolioPercentCostCompleted from "../components/views/list/columns/portfolio/PercentCostCompleted";
import PortfolioBudgetTotals from "../components/views/list/columns/portfolio/BudgetTotals";
import ProgramPercentCompleted from "../components/views/list/columns/program/PercentCompleted";
import ProgramPercentCostCompleted from "../components/views/list/columns/program/PercentCostCompleted";
import ProgramBudgetTotals from "../components/views/list/columns/program/BudgetTotals";
import { default as ProgramLinkedSystem } from "../components/views/list/columns/program/LinkedSystem";
import * as Favorite from "../components/views/list/columns/project/Favorite";
import * as ProjectVisibility from '../components/views/list/columns/project/ProjectVisibility';
import { default as ProjectLinkedSystem } from "../components/views/list/columns/project/LinkedSystem";
import { default as ResourceLinkedSystem } from "../components/views/list/columns/resource/LinkedSystem";
import { default as LastADSync } from "../components/views/list/columns/resource/LastADSync";
import { default as ActiveDirectoryId } from "../components/views/list/columns/resource/ActiveDirectoryId";
import { default as ChallengeIdeas } from "../components/views/list/columns/challenge/Ideas";
import * as Priority from "../components/views/list/columns/insights/Priority";
import { default as Votes } from "../components/views/list/columns/idea/Votes";
import { getValue as GetLayoutName } from "../components/views/list/columns/LayoutName";
import SyncDate from "../components/views/list/columns/project/SyncDate";
import SyncStatus from "../components/views/list/columns/project/SyncStatus";
import SyncDetails from "../components/views/list/columns/project/SyncDetails";
import { DisplayField } from "../components/common/DisplayField";
import { IOrderBy } from "../store/views";
import InsightsLate from '../components/views/list/columns/insights/Late';
import MyWorkStatus from '../components/views/list/columns/mywork/Status';
import { getValue as ImportedFromGetValue } from '../components/views/list/columns/subentity/ImportedFrom';
import { getValue as TaskBaselineGetValue } from '../components/views/list/columns/task/Baseline';
import { getValue as KeyDateBaselineGetValue } from '../components/views/list/columns/keyDate/Baseline';
import { getValue as TasksGetValue } from '../components/views/list/columns/project/Tasks';
import { toDictionaryByName } from '../components/utils/common';
import {getValue as TaskModeGetValue } from '../components/views/list/columns/task/Mode';
import { namesof } from '../store/services/metadataService';
import { IDependencyAttrs, ISteeringCommitteeAttrs } from '../entities/Subentities';

const columns = import.meta.glob('../components/views/list/columns/**/*.tsx');
const componentCache = new Dictionary<any>();

export class ViewService {
    static createListColumn<T>(componentName: string) {
        let component = componentCache[componentName];
        if (!component) {
            component = componentCache[componentName] = createLazyContainer<T>(columns[`../components/views/list/columns/${componentName}.tsx`]);
        }

        return component;
    }

    static getHandler(field: Metadata.Field) {
        const componentPath: string | undefined = field.settings?.views?.list?.componentPath;
        if (!componentPath) {
            return undefined;
        }

        const map: Dictionary<(entity: IExtensibleEntity, field?: Metadata.Field) => any> =
        {
            "portfolio/PercentCompleted": PortfolioPercentCompleted.getValue,
            "portfolio/PercentCostCompleted": PortfolioPercentCostCompleted.getValue,
            "portfolio/BudgetTotals": PortfolioBudgetTotals.getValue,
            "program/PercentCompleted": ProgramPercentCompleted.getValue,
            "program/PercentCostCompleted": ProgramPercentCostCompleted.getValue,
            "program/BudgetTotals": ProgramBudgetTotals.getValue,
            "program/LinkedSystem": ProgramLinkedSystem.getValue,
            "project/BudgetTotals": ProjectBudgetTotals.getValue,
            "project/Favorite": Favorite.getValue,
            "project/ProjectVisibility": ProjectVisibility.getValue,
            "project/LinkedSystem": ProjectLinkedSystem.getValue,
            "project/Tasks": TasksGetValue,
            "project/SyncDate": SyncDate.getValue,
            "project/SyncStatus": SyncStatus.getValue,
            "project/SyncDetails": SyncDetails.getValue,
            "resource/LinkedSystem": ResourceLinkedSystem.getValue,
            "resource/LastADSync": LastADSync.getValue,
            "resource/ActiveDirectoryId": ActiveDirectoryId.getValue,
            "challenge/Ideas": ChallengeIdeas.getValue,
            "idea/Votes": Votes.getValue,
            "insights/Late": InsightsLate.getValue,
            "mywork/Status": MyWorkStatus.getValue,
            "subentity/ImportedFrom": ImportedFromGetValue,
            "task/Baseline": TaskBaselineGetValue,
            "keyDate/Baseline": KeyDateBaselineGetValue,
            "LayoutName": GetLayoutName,
            "insights/Priority": Priority.getValue,
            "task/Mode": TaskModeGetValue
        };

        return map[componentPath];
    }

    static getFakeFieldColumnValue(entity: IExtensibleEntity, field: Metadata.Field): any {
        const handler = ViewService.getHandler(field);
        if (!handler) {
            console.warn('Value extractor map does not contain definition for field "' + field.name + '"');
            return undefined;
        }

        return handler(entity, field);
    }

    static getFieldName(column: string | Metadata.ISubViewColumn) {
        return typeof column === "string" ? column : column.id;
    }

    static buildColumn(
        fieldName: string,
        mapByName: Dictionary<Metadata.Field>,
        orderBy: IOrderBy | IOrderBy[] | undefined,
        isTimelineView: boolean,
        entityType: EntityType,
        columns?: Metadata.ISubViewColumn[],
        disableNavigation?: boolean,
        isArchived?: boolean
    ): IColumn | null {
        const field = mapByName[fieldName];
        if (!field) {
            return null;
        }

        const isMultiline = field.settings && field.settings.multiline;
        const defaultSettings = {
            isMultiline,
            minWidth: (isMultiline || field.type === Metadata.FieldType.Resource || field.type === Metadata.FieldType.User) ? 200 : 100,
            maxWidth: 300,
            isResizable: true
        }
        const fieldSettings = Object.assign({}, defaultSettings, field.settings?.views?.list || {});
        const origin = columns && columns.find(_ => _.id === field.id);
        const columnSettings = origin
            ? {
                ...fieldSettings,
                maxWidth: origin.width || fieldSettings.maxWidth,
                minWidth: origin.width || fieldSettings.minWidth
            }
            : fieldSettings;

        const fieldSort = orderBy && (Array.isArray(orderBy)
            ? orderBy.find(_ => _.fieldName === field.name)
            : orderBy.fieldName === field.name
                ? orderBy
                : undefined);

        disableNavigation ||= isArchived && field.type === Metadata.FieldType.Resource;

        return {
            ...columnSettings,
            key: field.id,
            name: columnSettings.label !== undefined ? columnSettings.label : Metadata.getLabel(field),
            fieldName: field.name,
            onRender: (item?: IExtensibleEntity, index?: number, column?: IColumn) => {
                if (columnSettings.componentPath) {
                    const component = ViewService.createListColumn<IListViewFieldColumn<IExtensibleEntity>>(columnSettings.componentPath);
                    return React.createElement(component, { entity: item, field, className: "font-14", isTimelineView, entityType, disableNavigation });
                }
                return React.createElement(
                    'span',
                    { className: "list-grid-cell" },
                    item ? React.createElement(DisplayField, { field, entity: item, entityType, disableNavigation }) : undefined);
            },
            isSorted: !!fieldSort,
            isSortedDescending: !!fieldSort && fieldSort.direction === SortDirection.DESC,
            headerClassName: columnSettings && columnSettings.iconName ? "with-icon" : undefined
        };
    }

    static buildCellRenderer<T>(item: T, entityType: EntityType, fields: Metadata.Field[], field: Metadata.Field, isFieldFake: (field: Metadata.Field) => boolean) {
        const fieldName = Metadata.findMatchingFieldName(fields, field, isFieldFake);
        const column = fieldName
            ? ViewService.buildColumn(fieldName, toDictionaryByName(fields), undefined, true, entityType)
            : undefined;
        return column ? column.onRender!(item, undefined, column) : React.createElement(React.Fragment);
    }

    static isMenuColumn(entityType: EntityType, column: string | Metadata.ISubViewColumn, index?: number): boolean {
        const mandatories = ViewService.getViewMandatoryFields(entityType);
        return mandatories?.length
            ? mandatories[0] === ViewService.getFieldName(column)
            : false;
    }

    static getViewMandatoryFields = (entityType: EntityType): string[] => {
        switch (entityType) {
            case EntityType.Portfolio:
            case EntityType.Program:
            case EntityType.Project:
            case EntityType.KeyDate:
            case EntityType.ActionItem:
            case EntityType.LessonLearned:
            case EntityType.ChangeRequest:
            case EntityType.Issue:
            case EntityType.Iteration:
            case EntityType.KeyDecision:
            case EntityType.PurchaseOrder:
            case EntityType.Invoice:
            case EntityType.Risk:
            case EntityType.StrategicPriority:
            case EntityType.Resource:
            case EntityType.Challenge:
            case EntityType.Idea:
            case EntityType.Roadmap:
            case EntityType.RoadmapItem:
            case EntityType.Objective:
            case EntityType.KeyResult:
            case EntityType.Task:
            case EntityType.Deliverable:
            case EntityType.ArchivedProject:
            case EntityType.TimeTrackingEntry:
            case EntityType.MyWork:                
                return namesof<IWithName>(["Name"]);
            case EntityType.Dependency:
                return namesof<IDependencyAttrs>(["Type"]);
            case EntityType.SteeringCommittee:
                return namesof<ISteeringCommitteeAttrs>(["Person"]);
            default:
                return [];
        }
    }

    static isMandatory = (field: Metadata.Field, mandatoryFields?: string[]): boolean =>
        !!mandatoryFields?.includes(field.name);
}

export interface IListViewColumn<TEntity> {
    entity: TEntity;
    className?: string;
    isTimelineView?: boolean;
    entityType?: EntityType;
    disableNavigation?: boolean;
}

export interface IListViewFieldColumn<TEntity> extends IListViewColumn<TEntity> {
    field: Metadata.Field;
}